import { ApiPath } from "../http/api-path"

export class ClipboardModel {
    id?: number
    content?: string
    type?: string

    isFile(): boolean {
        return this.type === 'file'
    }

    static fromJson(json: ClipboardModel): ClipboardModel {
        if (json.type === 'file') {
            if (!json.content?.startsWith('http')) {
                json.content = `${process.env.REACT_APP_BASE_URL}${ApiPath.fileUpload}/${json.content}`
            }
        }
        return Object.assign(new ClipboardModel(), json)
    }
}