import { ReactNode } from "react";

interface DialogType {
    children: ReactNode
    isOpen: boolean
    noBackground?: boolean
}

const Dialog = (props: DialogType) => {

    if (!props.isOpen) {
        return <div></div>
    }

    return (
        <div
            className="d-flex align-items-center justify-content-center position-absolute w-100 h-100 p-3"
            style={{ right: 0, top: 0,left:0, backgroundColor: 'rgba(0, 0, 0, 0.135)', zIndex: 5 }}>
            <div className={"rounded-3 m-3 col-md-6 col-12" + (!!props.noBackground ? '' : ' bg-white ')}>
                {
                    props.children
                }
            </div>
        </div>
    );
}

export default Dialog