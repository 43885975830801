export default class ChannelModel {
    id?: number
    name?: string

    static fromJson(json: ChannelModel): ChannelModel {
        const data = Object.assign(new ChannelModel(), json)
        return data
    }

    toString(): string {
        return JSON.stringify(this)
    }
}