import QRCode from "react-qr-code";
import Dialog from "../../../widgets/dialog";
import { MainProvider, useMainProvider } from "./main-provider";
import ItemClipboard from "./widgets/item-clipboard";
import { useHomeProvider } from "../provider";


function MainView() {
    return (
        <MainProvider>
            <View />
        </MainProvider>
    );
}

function View() {
    const provider = useMainProvider()
    const homeProvider = useHomeProvider()
    return (
        <div>
            <div>
                <header className="d-flex justify-content-between align-content-center p-2 text-bg-light position-sticky border-bottom"
                    style={{ zIndex: 2, top: 0 }}>
                    <div className="align-self-center" role="button" onClick={() => homeProvider.setOpenDrawer(true)}>Clipboard</div>
                    <div className="d-flex">

                        <button className="ms-2" onClick={() => provider.setSearchChannel(true)}>Find</button>
                        <button className="ms-2" onClick={() => provider.setShareChannelUrl()}>Share channel</button>
                        <button className="ms-2" onClick={() => provider.setOpen(true)}>Add</button>
                    </div>
                </header>
                <main>
                    <table className="m-3">
                        <tbody>
                            {
                                provider.clipboards.map((e) => (
                                    <ItemClipboard
                                        item={e}
                                        onImagePressed={(link) => provider.setImage(link)}
                                        onShowShare={() => provider.setShareClipboardUrl(e)}
                                        key={e.id} />
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        provider.isRequiredPassword &&
                        <div className="d-flex flex-column justify-content-center w-100 align-content-center p-3">
                            <div className="d-flex justify-content-center ">
                                <input
                                    type="password"
                                    className="col-12 col-md-6"
                                    ref={provider.passwordRef}
                                    placeholder="Password" />
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <button onClick={provider.onAuthentication}>Authentication</button>
                            </div>
                        </div>
                    }
                </main>

            </div>
            <Dialog isOpen={provider.isOpen}>
                <div className="p-3" >
                    <div>Content</div>
                    <div>
                        <textarea placeholder="Content" autoFocus ref={provider.contentRef} />
                    </div>
                    <div className="d-flex align-items-center">
                        <input type="file" multiple ref={provider.fileRef} style={{ minWidth: '100px', padding: '12px 0px' }} />
                        <button
                            className="rounded-pill"
                            style={{ width: 32, height: 32, padding: 0 }}
                            onClick={() => {
                                provider.fileRef.current!.value = ""
                            }}>❌</button>
                    </div>

                    <div className="mt-4 d-flex justify-content-end">
                        <button onClick={() => provider.setOpen(false)}>Cancel</button>
                        <button className="ms-2" onClick={provider.onSubmit}>Ok</button>
                    </div>
                </div>
            </Dialog>
            <Dialog isOpen={provider.shareUrl !== undefined}>
                <div className="p-3 d-flex flex-column align-items-center" >
                    <div>
                        <QRCode
                            size={128}
                            value={provider.shareUrl ?? ''}
                            viewBox={`0 0 128 128`}
                        />
                    </div>
                    <div className="mt-3">
                        <a href={provider.shareUrl} target="_blank" rel="noreferrer" className="mt-3">{provider.shareUrl}</a>
                    </div>
                    <div className="mt-3">
                        Input 👉 <b>{provider.shareUrl?.split('=').at(1)}</b> 👈 to <b>Search channel/clipboard</b>
                    </div>
                    <div className="mt-4 d-flex justify-content-end">
                        <button onClick={() => provider.setShareUrl(undefined)}>Close</button>
                    </div>
                </div>
            </Dialog>
            <Dialog isOpen={provider.isSearchChannel}>
                <div className="p-3" >
                    <div className="mb-2">
                        <select name="Channel or clipboard" id="channel-or-clip" ref={provider.optionRef}>
                            <option value="clipboard">Clipboard</option>
                            <option value="channel">Channel</option>
                        </select>
                    </div>
                    <input type="text" placeholder="Clipboard id or channel id" autoFocus ref={provider.idRef} />
                    <div className="mt-4 d-flex justify-content-end">
                        <button onClick={() => provider.setSearchChannel(false)}>Cancel</button>
                        <button className="ms-2" onClick={provider.onSearch}>Ok</button>
                    </div>
                </div>
            </Dialog>
            <Dialog isOpen={!!provider.image}
                noBackground>
                <div className="position-absolute" style={{ top: 0, left: 0, width: '100vw', height: '100vh', }}>
                    <div
                        onClick={() => provider.setImage(undefined)}
                        style={{ width: '100vw', height: '100vh', backgroundColor: '#dddddd2a', zIndex: 20, position: 'absolute' }} >
                        <img src={provider.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain', padding: 16 }} />
                    </div>
                    <button
                        onClick={() => provider.setImage(undefined)}
                        className="position-absolute"
                        style={{ top: 16, right: 16, zIndex: 32 }}>
                        ❌
                    </button>
                </div>


            </Dialog>
        </div>
    );
}

export default MainView