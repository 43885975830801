import { ReactElement, RefObject, createContext, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ClipboardModel } from "../../../model/clipboard-model";
import { HttpBuilder } from "../../../http/http_builder";
import { ApiPath } from "../../../http/api-path";
import { RoutePath } from "../../../route/route-path";
import { HttpStatusCode } from "axios";
import { FileModel } from "../../../model/file-model";
import { useRootProvider } from "../../root/provider";


const MainContext = createContext<MainType>(null!)

interface MainType {
    clipboards: Array<ClipboardModel>
    setClipboards: (clipboards: Array<ClipboardModel>) => void
    shareUrl: string | undefined
    setShareUrl: (shareUrl: string | undefined) => void
    isOpen: boolean
    setOpen: (isOpen: boolean) => void
    isRequiredPassword: boolean
    setRequiredPassword: (isRequiredPassword: boolean) => void
    isSearchChannel: boolean
    setSearchChannel: (isSearchChannel: boolean) => void
    isShareChannelOpen: boolean
    setIsShareChannelOpen: (isShareChannelOpen: boolean) => void
    image: string | undefined
    setImage: (image: string | undefined) => void
    contentRef: RefObject<HTMLTextAreaElement>
    fileRef: RefObject<HTMLInputElement>
    passwordRef: RefObject<HTMLInputElement>
    idRef: RefObject<HTMLInputElement>
    optionRef: RefObject<HTMLSelectElement>
    fetchData: () => Promise<void>
    onSubmit: () => Promise<void>
    uploadFileIfExist: () => Promise<boolean>
    makeNewClipboard: () => any
    setShareChannelUrl: () => void
    setShareClipboardUrl: (e: ClipboardModel) => any
    onSearch: () => void
    onAuthentication: () => void
    checkNewClipboard: () => Promise<void>
}

export function useMainProvider() {
    return useContext(MainContext)
}

export function MainProvider({ children }: { children: ReactElement }) {
    const [clipboards, setClipboards] = useState<Array<ClipboardModel>>([])
    const [shareUrl, setShareUrl] = useState<string | undefined>()
    const [isOpen, setOpen] = useState<boolean>(false)
    const [isRequiredPassword, setRequiredPassword] = useState<boolean>(false)
    const [isSearchChannel, setSearchChannel] = useState<boolean>(false)
    const [isShareChannelOpen, setIsShareChannelOpen] = useState<boolean>(false)
    const [image, setImage] = useState<string | undefined>()
    const contentRef = useRef<HTMLTextAreaElement>(null)
    const fileRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const idRef = useRef<HTMLInputElement>(null)
    const optionRef = useRef<HTMLSelectElement>(null)

    const [search, setSearch] = useSearchParams()
    const rootProvider = useRootProvider()

    const channelId = search.get('channel')
    const channelKey = `channel-${channelId}`

    async function fetchData(): Promise<void> {
        if (!channelId) return
        const password = localStorage.getItem(channelKey)
        const result = await HttpBuilder.main()
            .setPath(ApiPath.clipboardByChannel)
            .setBody({
                channelId: +channelId,
                password: password
            })
            .setFromJson((json) => json.map((e: any) => ClipboardModel.fromJson(e)))
            .post<Array<ClipboardModel>>()
        setRequiredPassword(result.code === HttpStatusCode.Forbidden)
        if (!result.isOk()) {
            return
        }
        if (clipboards.length && result.data?.length) {
            const isSame = result.data?.at(0)?.id === clipboards.at(0)?.id
            if (isSame) return
        }

        setClipboards(result.data!)
    }


    async function onSubmit(): Promise<void> {
        const file = fileRef.current?.files?.item(0)
        const content = contentRef.current?.value.trim()
        if (!file && !content) return

        const isSuccess = await uploadFileIfExist()
        if (!isSuccess) {
            return
        }

        await makeNewClipboard()

        fetchData()
        setOpen(false)
    }

    async function uploadFileIfExist(): Promise<boolean> {
        const files = fileRef.current?.files
        if (!files) return true

        const data = new FormData()
        for (var i = 0; i < files.length; i++) {
            const it = files.item(i)
            if (it) {
                data.append('files', it)
            }
        }
        const result = await HttpBuilder.main().setPath(ApiPath.fileUpload)
            .setBody(data)
            .setFromJson((list) => list.map(FileModel.fromJson))
            .post<Array<FileModel>>()
        if (!result.isOk()) {
            rootProvider.makeToast("Error when upload file")
            return false
        }
        for (const it of result.data ?? []) {
            const clipResult = await HttpBuilder.main()
                .setPath(ApiPath.clipboard)
                .setBody({
                    channelId: +(channelId ?? 0),
                    content: it.filename,
                    type: 'file'
                })
                .post()
            if (!clipResult.isOk()) {
                rootProvider.makeToast("Error when create clipboard")
                return false
            }
        }

        return true
    }


    async function makeNewClipboard() {
        const value = contentRef.current?.value?.trim()
        if (!value) return
        await HttpBuilder.main()
            .setPath(ApiPath.clipboard)
            .setBody({
                channelId: +(channelId ?? 0),
                content: value,
                type: 'text'
            })
            .post()
    }

    function setShareChannelUrl(): void {
        const url = window.location.origin + RoutePath.initial + `?channel=${channelId}`
        setShareUrl(url)
    }

    function setShareClipboardUrl(e: ClipboardModel) {
        const url = window.location.origin + RoutePath.clipDetail + `?id=${e.id}`
        setShareUrl(url)
    }

    function onSearch(): void {
        const id = idRef.current?.value
        if (!id) return
        const option = optionRef.current?.value
        if (option === 'channel') {
            setSearchChannel(false)
            return setSearch((prev) => new URLSearchParams({ ...prev, 'channel': id }))
        }
        const url = window.location.origin + RoutePath.clipDetail + `?id=${id}`
        window.open(url, '_blank')
    }

    function onAuthentication(): void {
        localStorage.setItem(channelKey, passwordRef.current?.value ?? '')
        fetchData()
    }

    async function checkNewClipboard(): Promise<void> {

        if (!channelId) return
        const password = localStorage.getItem(channelKey)
        const result = await HttpBuilder.main()
            .setPath(ApiPath.checkNew)
            .setBody({
                channelId: +channelId,
                password: password
            })
            .setFromJson(ClipboardModel.fromJson)
            .post<ClipboardModel>()
        if (!result.isOk()) {
            return
        }
        if (result.data?.id) {
            if (!clipboards.length || (result.data?.id && result.data.id !== clipboards.at(0)?.id)) {
                fetchData()
            }
        }
    }

    useEffect(() => {
        if(!channelId) return
        setClipboards([])
        fetchData()
    }, [channelId])

    useEffect(() => {
        if (!channelId) return
        const timer = setInterval(checkNewClipboard, 3 * 1000)
        setRequiredPassword(false)

        return () => {
            clearInterval(timer)
        }
    }, [clipboards])

    const value = {
        clipboards,
        setClipboards,
        shareUrl,
        setShareUrl,
        isOpen,
        setOpen,
        isRequiredPassword,
        setRequiredPassword,
        isSearchChannel,
        setSearchChannel,
        isShareChannelOpen,
        setIsShareChannelOpen,
        image,
        setImage,
        contentRef,
        fileRef,
        passwordRef,
        idRef,
        optionRef,
        fetchData,
        onSubmit,
        uploadFileIfExist,
        makeNewClipboard,
        setShareChannelUrl,
        setShareClipboardUrl,
        onSearch,
        onAuthentication,
        checkNewClipboard,
    }
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    )
}

