import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './router/home';
import { RoutePath } from './route/route-path';
import ClipboardDetailPage from './router/clipboard-detail';
import RootPage from './router/root';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <RootPage>
      <BrowserRouter>
        <Routes>
          <Route path={RoutePath.initial} element={<HomePage />} />
          <Route path={RoutePath.clipDetail} element={<ClipboardDetailPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </RootPage>
  );
}

export default App;
