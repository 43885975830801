
import { ReactElement, createContext, useContext } from "react";
import { toast } from 'react-toastify'

const RootContext = createContext<RootType>(null!)

interface RootType {
    makeToast: (msg: any) => void
}

export function useRootProvider() {
	return useContext(RootContext)
}


export function RootProvider({ children }: { children: ReactElement }) {

	function makeToast(msg: any): void {
		toast.success(msg,{
			hideProgressBar: true,
			closeOnClick:true,
		})
	}

    const value = {
        makeToast,
    }
	return (
		<RootContext.Provider value={value}>
			{children}
		</RootContext.Provider>
	)
}
