import { ClipboardModel } from "../../../../model/clipboard-model";
import linkifyStr from "linkify-string";
import * as linkify from "linkifyjs";
import { useEffect, useState } from "react";
import './index.css'
import { HttpBuilder } from "../../../../http/http_builder";
import { ApiPath } from "../../../../http/api-path";
import { MetaModel } from "../../../../model/meta-model";
import { useRootProvider } from "../../../root/provider";

interface ItemClipboardType {
    item: ClipboardModel
    onShowShare?: () => {},
    onImagePressed?: (link: string) => void,
}

const ItemClipboard = (props: ItemClipboardType) => {
    const [components, setComponents] = useState<{
        type: string;
        value: string;
        isLink: boolean;
        href: string;
        start: number;
        end: number;
    }[]>()
    const [meta, setMeta] = useState<MetaModel | undefined>()
    const rootProvider = useRootProvider()

    const content = props.item.content
    const imageLink = components?.find((e) => e.type === 'url')?.href


    function onCopy() {
        navigator.clipboard.writeText(props.item.content ?? '')
        rootProvider.makeToast('Copied!')
    }

    function _showShare(): void {
        if (props.onShowShare != null)
            props.onShowShare()
    }

    async function getMeta() {
        if (!imageLink) return
        if (imageLink.startsWith(process.env.REACT_APP_BASE_URL ?? '')) return
        try {
            var cache = localStorage.getItem(imageLink)

            if (cache != null) {
                const obj = JSON.parse(cache)
                if (!!Object.keys(obj).length) {
                    setMeta(MetaModel.fromJson(obj))
                    return
                }
            }
            const result = await HttpBuilder.main()
                .setPath(ApiPath.meta)
                .setQuery({
                    url: imageLink,
                })
                .setFromJson(MetaModel.fromJson)
                .get<MetaModel>()

            if (!result.isOk()) return
            setMeta(result.data)
            if (result.data) {
                localStorage.setItem(imageLink, JSON.stringify(result.data))
            }

        } catch (_) { }
    }


    useEffect(() => {
        if (content) {
            const result = linkify.find(content)
            setComponents(result)
        }
    }, [])

    useEffect(() => {
        getMeta()
    }, [imageLink])

    function _onShowImage(url?: string): void {

        if (props.onImagePressed && !!url) {
            props.onImagePressed(url)
        }
    }

    function _onOpenLink(): void {
        window.open(imageLink, '_blank')
    }

    return (
        <tr>
            <td>{props.item.id}</td>
            <td>
                <div style={{ width: 100 }}>
                    {
                        imageLink &&
                        <img src={imageLink} alt="" width={100} onClick={() => _onShowImage(imageLink)} style={{ cursor: 'pointer' }} />
                    }
                    {
                        meta?.image &&
                        <img src={meta.image} width={100} alt="" onClick={() => _onShowImage(meta.image)} style={{ cursor: 'pointer' }} />
                    }
                </div>
            </td>
            <td className="w-100 position-relative" style={{ whiteSpace: 'pre-line' }}>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: linkifyStr(content!!, { target: '_blank' }) }} />
                    {
                        meta?.isNotEmpty() &&
                        <div

                            className="d-flex mt-3 border-success p-2 border-1 rounded-2 align-items-start"
                            style={{ border: '1px solid' }}>
                            <img src={meta.image} width={200} alt="" className="rounded-2" onClick={() => _onShowImage(meta.image)} style={{ cursor: 'pointer', objectFit: 'contain' }} />
                            <div className="ms-3" >
                                <div onClick={_onOpenLink} style={{ cursor: 'pointer' }}><b>{meta.title}</b></div>
                                {
                                    meta.desc &&
                                    <div className="mt-1" dangerouslySetInnerHTML={{ __html: linkifyStr(meta.desc!!, { target: '_blank' }) }}></div>
                                }
                            </div>
                        </div>
                    }
                </div>

                {
                    props.item.isFile() &&
                    <a href={`${props.item.content}?type=download`} target="_blank" rel="noopener noreferrer" download>
                        <button
                            className="position-absolute "
                            style={{ top: 8, right: 12, zIndex: 1, padding: 0, width: 36, height: 36 }}>⬇️</button>
                    </a>

                }
            </td>
            <td>
                <div className="d-flex">
                    <button onClick={_showShare}>🔗</button>
                    <button onClick={onCopy} className="ms-2">📋</button>
                </div>
            </td>
        </tr>
    );
}

export default ItemClipboard