
import { ReactNode } from "react";
import { RootProvider } from "./provider";

interface RootProps {
    children: ReactNode
}

function RootPage(props: RootProps) {
    return (
        <RootProvider>
            <View children={props.children} />
        </RootProvider>
    );
}

function View(props: RootProps) {
    return (
        <div>
            {
                props.children
            }
        </div>
    );
}

export default RootPage
