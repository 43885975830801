export class FileModel {
    fieldname?: string
    originalname?: string
    encoding?: string
    mimetype?: string
    destination?: string
    filename?: string
    path?: string
    size?: number

    static fromJson(json: FileModel): FileModel {
        return Object.assign(new FileModel(), json)
    }
}
