import axios, { Method } from "axios";
import { objectToCamelCase } from "./utils";
import { BaseResponse } from "./base_response";
import { Builder, JsonDeserialize } from "./builder";


export class HttpBuilder implements Builder {


    private url?: string
    private path?: string
    private query?: object
    private param?: any
    private headers?: object
    private body?: any
    private fromJson?: any

    HttpBuilder() { }

    static main(): HttpBuilder {
        const mainBuilder = new HttpBuilder()

        mainBuilder.setBaseUrl(process.env.REACT_APP_BASE_URL ?? 'http://192.168.1.12:3000')
        return mainBuilder
    }

    setHeaders(headers?: object | undefined): Builder {
        this.headers = headers
        return this
    }

    setBaseUrl(url: string): Builder {
        this.url = url
        return this
    }
    setParam(param: object): Builder {
        this.param = param
        return this
    }
    setPath(path: string): Builder {
        this.path = path
        return this
    }
    setQuery(query: object): Builder {
        this.query = query
        return this
    }
    setBody(body: any): Builder {
        this.body = body
        return this
    }

    setFromJson<T>(fromJson?: JsonDeserialize<T>): Builder {
        this.fromJson = fromJson
        return this
    }

    private buildUrl(): string {
        this.url ??= 'http://192.168.1.12:3000'

        for (const key in this.param) {
            if (this.param[key]) {
                this.path = this.path?.replaceAll(`{${key}}`, this.param[key])
            }
        }
        return this.path ?? ''
    }

    private getHeaders(): object {
        const user = JSON.parse(localStorage.getItem('Constants.user') ?? "{}")
        return {
            ...this.headers,
            user: user?.id,
            token: user?.token
        }
    }

    async get<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('GET')
    }

    async post<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('POST')
    }

    async put<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('PUT')
    }

    async delete<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('DELETE')
    }

    async patch<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('PATCH')
    }

    private async _perform<T>(method: Method) {
        try {

            axios.interceptors.response.use((response) => {
                response.data = objectToCamelCase(response.data)

                return response;
            }, (error) => {
                error.response.data = objectToCamelCase(error.response.data)

                return error.response
            });
            const response = await axios({
                method: method,
                baseURL: this.url,
                url: this.buildUrl(),
                data: this.body,
                params: this.query,
                withCredentials: false,
                headers: this.getHeaders(),
            })

            let rData = response.data
            if (this.fromJson) {
                try {
                    rData = this.fromJson(response.data)
                } catch (e) { }
            }

            return new BaseResponse<T>(
                response.headers,
                rData,
                response.data?.code ?? response.data?.statusCode ?? response.status,
                response.data?.message,
            )
        } catch (e: any) {
            console.log(e);

            return new BaseResponse<T>(
                undefined,
                undefined,
                99,
                'Client Exception',
            )
        }

    }
}


