
export class MetaModel {
    title?: string
    desc?: string
    type?: string
    image?: string

    static fromJson(json: MetaModel): MetaModel {
        const data = Object.assign(new MetaModel(), json)
        return data
    }

    isNotEmpty(): boolean {
        return (!!this.title || !!this.desc || !!this.type || !!this.image)
    }
}