import { DrawerProvider, useDrawerProvider } from "./drawer-provider";
import ItemChannel from "./widgets/item-channel";
import Dialog from "../../../widgets/dialog";
import { useHomeProvider } from "../provider";
import ChannelModel from "../../../model/channel-model";


function Drawer() {
   return (
      <DrawerProvider>
         <View />
      </DrawerProvider>
   );
}

function View() {
   const provider = useDrawerProvider()
   const homeProvider = useHomeProvider()

   function _onChannelPressed(e: ChannelModel): void {
      homeProvider.setOpenDrawer(false)
      provider.onChannelPressed(e)
   }

   return (
      <div>
         <div style={{ width: '300px' }} className="vh-100 border-end bg-white  overflow-scroll">
            <div className="d-flex justify-content-between align-content-center p-2 text-bg-light position-sticky"
               style={{ top: 0, zIndex: 2 }}>
               <div className="align-self-center">Channel</div>
               <button onClick={() => provider.setOpen(true)}>Add</button>
            </div>
            <hr className="m-0" />
            <div>
               {
                  provider.channels.map((e) => (
                     <ItemChannel
                        channel={e}
                        onPressed={() => _onChannelPressed(e)}
                        key={e.id} />
                  ))
               }
            </div>

         </div>
         <Dialog isOpen={provider.isOpen}>
            <div className="p-3" >
               <div>Name</div>
               <input type="text" placeholder="Channel name" autoFocus ref={provider.channelNameRef} />
               <div className="mt-2">Password</div>
               <input type="password" placeholder="Password" ref={provider.passwordRef} />
               <div className="mt-4 d-flex justify-content-end">
                  <button onClick={() => provider.setOpen(false)}>Cancel</button>
                  <button className="ms-2" onClick={provider.onSubmit}>Ok</button>
               </div>
            </div>
         </Dialog>
      </div>

   );
}

export default Drawer